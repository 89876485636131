.dropdownWrapper {
    position: relative;
    display: inline-block;
}

.button {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: var(--spacing-core-3) var(--spacing-core-8);
}

.icon {
    min-width: 19px;
    min-height: 19px;
    fill: currentColor;
}

.dropdown {
    top: 53px - 16px;
    right: -12px;
    width: 270px;
    background-color: var(--color-background-primary-default);
    position: absolute;
    z-index: 100;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
    border-radius: 14px;
    overflow: hidden;
    transition:
        opacity,
        transform 0s ease;
    transition-duration: 0.3s;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-25px);

    > li:not(:last-of-type) {
        .dropdownItemContent {
            border-bottom: 1px solid #e0e0e0;
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdownItem {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0 14px;

    &:hover {
        cursor: pointer;
        background-color: #f3f6f8;
    }

    .dropdownItemContent {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 20px 6px;
    }
}
