@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.background {
    opacity: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #000 0%, rgb(0 0 0 / 0%) 60%);
    box-sizing: border-box;
}

.descriptionContainer {
    margin-top: var(--spacing-core-2);
}

.duration {
    display: inline-flex;
    align-items: center;
    position: relative;
    top: calc(var(--spacing-core-1) * -1);

    &::before {
        display: block;
        content: '';
        height: 2px;
        width: var(--sizing-core-6);
        margin: 0 var(--spacing-core-2);
        background-color: #000;
    }
}

.headingContainer {
    padding: var(--spacing-core-4) 0 0;
    opacity: 1;
}

@include mq.mq($from: large) {
    .root {
        position: absolute;
        top: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

    .headingContainer {
        position: absolute;
        left: 0;
        top: var(--spacing-core-8);
        color: var(--color-foreground-inverted-default);
        padding: 0 88px;
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .duration,
    .description,
    .title {
        text-align: center;
    }

    .background {
        opacity: 0.65;
    }

    
    .description {
        color: var(--color-foreground-inverted-default);
    }

    .duration {
        &::before {
            background-color: var(--color-background-primary-default);
        }
    }
}
